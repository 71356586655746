// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/common/closeIcon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--evX4C {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  background: var(--blue-50, #EFF6FF);
  padding: 16px;
}
.s-module__wrap--evX4C .s-module__left--COlaU {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.s-module__wrap--evX4C .s-module__img--DUC8b {
  width: 20px;
  height: 20px;
}
.s-module__wrap--evX4C .s-module__img--DUC8b svg {
  transform: rotate(0deg);
}
.s-module__wrap--evX4C .s-module__title--VCbeo {
  color: #1E40AF;
  margin-left: 16px;
  font-weight: 500;
  font-size: 14px;
}
.s-module__wrap--evX4C .s-module__closeIcon--uG9n6 {
  margin-left: 8px;
  display: block;
  width: 14px;
  height: 14px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center;
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Alert/s.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,mCAAA;EACA,aAAA;AACJ;AAPA;EASQ,aAAA;EACA,mBAAA;EACA,mBAAA;AACR;AAZA;EAcQ,WAAA;EACA,YAAA;AACR;AAhBA;EAkBY,uBAAA;AACZ;AAnBA;EAsBQ,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;AAAR;AAzBA;EA6BQ,gBAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,2EAAA;EACA,qBAAA;EACA,4BAAA;EACA,eAAA;AADR","sourcesContent":[".wrap {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    border-radius: 6px;\n    background: var(--blue-50, #EFF6FF);\n    padding: 16px;\n\n    .left {\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n    }\n    .img {\n        width: 20px;\n        height: 20px;\n\n        svg {\n            transform: rotate(0deg);\n        }\n    }\n    .title {\n        color: #1E40AF;\n        margin-left: 16px;\n        font-weight: 500;\n        font-size: 14px;\n    }\n\n    .closeIcon {\n        margin-left: 8px;\n        display: block;\n        width: 14px;\n        height: 14px;\n        background: url('assets/common/closeIcon.svg') no-repeat center center;\n        background-size: 100%;\n        background-repeat: no-repeat;\n        cursor: pointer;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--evX4C`,
	"left": `s-module__left--COlaU`,
	"img": `s-module__img--DUC8b`,
	"title": `s-module__title--VCbeo`,
	"closeIcon": `s-module__closeIcon--uG9n6`
};
export default ___CSS_LOADER_EXPORT___;
