import { Request } from '../request';

export const getProviderDetailTesting = ({ slug }:{
    slug: string;
}) => Request.get({
    url: 'https://app.kiwihealth.com/patient-server/public/provider/detail',
    params: {
        slug,
    },
});
